@keyframes rain {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
}

@keyframes moon-glow {
  0%, 100% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.8), 0 0 60px rgba(255, 255, 255, 0.5);
  }
}

@keyframes twinkle {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}

.star-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: -1;
  overflow: hidden;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 50%;
  animation: twinkle 2s infinite alternate ease-in-out;
}

.rain-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 2;
  overflow: hidden;
}

.raindrop {
  position: absolute;
  width: 2px;
  height: 20px;
  animation: rain linear infinite;
}

.raindrop.light-blue {
  background: rgba(173, 216, 230, 0.7);
}

.raindrop.lighter-blue {
  background: rgba(192, 232, 250, 0.7);
}

.moon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 80px;
  height: 80px;
  background-color: #c0c0c0;
  border-radius: 50%;
  animation: moon-glow 2s infinite ease-in-out;
  z-index: 1;
  transition: box-shadow 0.3s ease;
}

.moon:hover {
  box-shadow: 0 0 40px rgba(255, 255, 255, 1);
}

.grass {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 20vh;
  background-color: #013220;
  z-index: 1;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0d1b2a;
  font-family: 'Arial', sans-serif;
  color: #d3d3d3;
  position: relative;
  z-index: 3;
}

.header {
  text-align: center;
  margin-bottom: 20px;
  z-index: 3;
}

h1 {
  margin: 0;
  font-size: 2em;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
}

.links a {
  display: block;
  width: 80%;
  max-width: 300px;
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #3a3a3a;
  color: #d3d3d3;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s ease;
}

.links a:hover {
  background-color: #555;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
}

.audio-control {
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  padding: 0;
  background-color: #3a3a3a;
  color: #d3d3d3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 3;
  transition: background-color 0.3s, box-shadow 0.3s ease;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-control:hover {
  background-color: #555;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
}

.youtube-player {
  display: none;
  position: absolute;
  top: -1000px;
  left: -1000px;
}
